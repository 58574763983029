$shorter-width: 150px;
$short-width: 200px;
$normal-width: 300px;
$wide-width: 400px;
$wider-width: 550px;
$widest-width: 600px;

$short-height: 30px;
$normal-height: 42px;
$tall-height: 48px;
$taller-height: 58px;

$button-border-radius: 0px;

$font-regular: 400;
$font-medium: 500;
$font-bold: 700;
$font-bolder: 900;