@import '../theme/variables/sizes';

@mixin text-primary {
  font-family: PwC ITC Charter, sans-serif;
  font-style: normal;
  font-weight: $font-regular;
}

@mixin text-regular {
  font-family: PwC Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: $font-regular;
}

@mixin text-medium {
  font-family: PwC Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: $font-medium;
}

@mixin text-bold {
  font-family: PwC Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: $font-bold;
}

@mixin text-bolder {
  font-family: PwC Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: $font-bolder;
}

// regular texts

@mixin text-1-regular {
  @include text-regular;

  font-size: 10px;
  line-height: 12px;
}

@mixin text-2-regular {
  @include text-regular;

  font-size: 12px;
  line-height: 14px;
}

@mixin text-3-regular {
  @include text-regular;

  font-size: 14px;
  line-height: 20px;
}

@mixin text-4-regular {
  @include text-regular;

  font-size: 16px;
  line-height: 24px;
}

@mixin text-5-regular {
  @include text-regular;

  font-size: 20px;
  line-height: 24px;
}

@mixin text-6-regular {
  @include text-primary;

  font-size: 28px;
  line-height: 34px;
}

@mixin text-7-regular {
  @include text-primary;

  font-size: 40px;
  line-height: 52px;
}

// medium texts

@mixin text-1-medium {
  @include text-medium;

  font-size: 10px;
  line-height: 12px;
}

@mixin text-2-medium {
  @include text-medium;

  font-size: 12px;
  line-height: 14px;
}

@mixin text-3-medium {
  @include text-medium;

  font-size: 14px;
  line-height: 20px;
}

@mixin text-4-medium {
  @include text-medium;

  font-size: 16px;
  line-height: 24px;
}

@mixin text-5-medium {
  @include text-medium;

  font-size: 20px;
  line-height: 24px;
}

@mixin text-6-medium {
  @include text-primary;

  font-size: 28px;
  line-height: 34px;
}

@mixin text-7-medium {
  @include text-primary;

  font-size: 40px;
  line-height: 52px;
}

// bold texts

@mixin text-1-bold {
  @include text-bold;

  font-size: 10px;
  line-height: 12px;
}

@mixin text-2-bold {
  @include text-bold;

  font-size: 12px;
  line-height: 14px;
}

@mixin text-3-bold {
  @include text-bold;

  font-size: 14px;
  line-height: 20px;
}

@mixin text-4-bold {
  @include text-bold;

  font-size: 16px;
  line-height: 24px;
}

@mixin text-5-bold {
  @include text-bold;

  font-size: 20px;
  line-height: 24px;
}

@mixin text-6-bold {
  @include text-primary;

  font-size: 28px;
  line-height: 34px;
}

@mixin text-7-bold {
  @include text-primary;

  font-size: 42px;
  line-height: 52px;
}

// bolder texts

@mixin text-1-bolder {
  @include text-bolder;

  font-size: 10px;
  line-height: 12px;
}

@mixin text-2-bolder {
  @include text-bolder;

  font-size: 12px;
  line-height: 14px;
}

@mixin text-3-bolder {
  @include text-bolder;

  font-size: 14px;
  line-height: 20px;
}

@mixin text-4-bolder {
  @include text-bolder;

  font-size: 16px;
  line-height: 24px;
}

@mixin text-5-bolder {
  @include text-bolder;

  font-size: 20px;
  line-height: 24px;
}

@mixin text-6-bolder {
  @include text-primary;

  font-size: 28px;
  line-height: 34px;
}

@mixin text-7-bolder {
  @include text-primary;

  font-size: 40px;
  line-height: 52px;
}
