.flex {
  display: flex;
}

.flex.column {
  flex-direction: column;
}

.grow1 {
  flex-grow: 1;
  flex-basis: 0;
}

.grow2 {
  flex-grow: 2;
  flex-basis: 0;
}

.grow3 {
  flex-grow: 3;
  flex-basis: 0;
}

.vcenter {
  align-items: center;
}

.hcenter {
  justify-content: center;
}
