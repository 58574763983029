@use "./variables/sizes" as sizes;
@use "src/assets/shared/theme/variables/colors" as colors;
@use "src/assets/shared/mixins/texts" as texts;

@import "~@angular/material/theming";

@include mat-core();

$custom-orange: (
  100: #d04a02,
  // #f2771d,
  500: #d04a02,
  // #f2771d,
  700: #d04a02,
  // #f2771d,
  contrast:
    (
      100: #000000,
      500: #000000,
      700: #000000
    )
);

$custom-red: (
  100: colors.$error-primary,
  // #e26c6c,
  500: colors.$error-primary,
  // #e26c6c,
  700: colors.$error-primary,
  // #e26c6c,
  contrast:
    (
      100: #000000,
      500: #000000,
      700: #000000
    )
);

$custom-light-blue: (
  100: colors.$blue-king,
  // #1089ff,
  500: colors.$blue-king,
  // #1089ff,
  700: colors.$blue-king,
  // #1089ff,
  contrast:
    (
      100: #ffffff,
      500: #ffffff,
      700: #ffffff
    )
);

$custom-blue: (
  100: colors.$blue-payee,
  // #455BED,
  500: colors.$blue-payee,
  // #455BED,
  700: colors.$blue-payee,
  // #455BED,
  contrast:
    (
      100: #ffffff,
      500: #ffffff,
      700: #ffffff
    )
);

$custom-dark-blue: (
  100: colors.$brand-quaternary,
  // #1c0f5a,
  500: colors.$brand-quaternary,
  // #1c0f5a,
  700: colors.$brand-quaternary,
  // #1c0f5a,
  contrast:
    (
      100: #ffffff,
      500: #ffffff,
      700: #ffffff
    )
);

$custom-green: (
  100: colors.$success-primary,
  // #8bf0ad,
  500: colors.$success-primary,
  // #8bf0ad,
  700: colors.$success-primary,
  // #8bf0ad,
  contrast:
    (
      100: #000000,
      500: #000000,
      700: #000000
    )
);

$custom-black: (
  100: #000000,
  500: #000000,
  700: #000000,
  contrast: (
    100: #ffffff,
    500: #ffffff,
    700: #ffffff
  )
);

$custom-white: (
  100: #ffffff,
  500: #ffffff,
  700: #ffffff,
  contrast: (
    100: #000000,
    500: #000000,
    700: #000000
  )
);

$custom-gray: (
  100: #929292,
  500: #929292,
  700: #929292,
  contrast: (
    100: #000000,
    500: #000000,
    700: #000000
  )
);

$main-theme-primary: mat-palette($custom-dark-blue, 100, 500, 700);
$main-theme-accent: mat-palette($custom-orange, 100, 500, 700);
$main-theme-warn: mat-palette($custom-red, 100, 500, 700);

$main-theme: mat-light-theme($main-theme-primary, $main-theme-accent, $main-theme-warn);

@include angular-material-theme($main-theme);

.alt-theme {
  $alt-primary: mat-palette($custom-light-blue, 100, 500, 700);
  $alt-accent: mat-palette($custom-green, 100, 500, 700);
  $alt-theme: mat-light-theme($alt-primary, $alt-accent, $main-theme-warn);

  @include angular-material-theme($alt-theme);
}

.alt-theme2 {
  $alt-primary: mat-palette($custom-blue, 100, 500, 700);
  $alt-accent: mat-palette($custom-gray, 100, 500, 700);
  $alt-theme: mat-light-theme($alt-primary, $alt-accent, $main-theme-warn);

  @include angular-material-theme($alt-theme);
}

.bw-theme {
  $alt-primary: mat-palette($custom-black, 100, 500, 700);
  $alt-accent: mat-palette($custom-white, 100, 500, 700);
  $alt-theme: mat-light-theme($alt-primary, $alt-accent, $main-theme-warn);

  @include angular-material-theme($alt-theme);
}

.mat-form-field.mat-form-field-appearance-outline {
  @include texts.text-3-regular;
  line-height: normal;
  letter-spacing: -0.03em;
  margin-bottom: 19px;

  color: #363740;

  .mat-form-field-wrapper {
    margin: 0px;
    padding-bottom: 3.5px;
  }

  .mat-form-field-flex {
    transition: 0.5s;
    background: #f6f7f9;
    box-shadow: 0px 4px 0px #dfe0eb;
    border-radius: 5px;
    display: flex !important;
    align-items: center !important;

    height: 30px;
  }

  &.mat-focused .mat-form-field-flex {
    background: #e5e5e5;
  }

  .mat-form-field-label {
    transition: 0.5s;
    margin-top: -1em;
  }

  &:not(.mat-focused) .mat-form-field-label:not(.mat-empty) {
    margin-top: 0px;
  }

  &.mat-focused .mat-form-field-label {
    margin-top: -0.3em;
  }

  .mat-form-field-infix {
    display: flex !important;
    align-items: center !important;
    padding: 0 !important;
    border-top: 7.6px solid transparent;
    margin-bottom: 2.4px;
  }

  .mat-form-field-subscript-wrapper {
    padding: 0px !important;
    margin-top: 21.4px;
  }

  .mat-form-field-suffix {
    min-width: 30px;
    text-align: center;
  }

  .mat-form-field-outline * {
    border: 0 !important;
  }
}

.mat-dialog-container {
  background: transparent;
  box-shadow: none;
  padding: 0px !important;
  width: unset !important;
  height: unset !important;
}

.cdk-overlay-dark-backdrop {
  //background: rgba(22, 22, 22, 0.75);
  background: rgba(0, 0, 0, 0.85);
}

.mat-calendar {
  font-family:
    PwC Helvetica Neue,
    sans-serif;
}

.custom-input-field {
  position: relative;
  display: flex;
  flex-direction: column;
  --height: 40px;
  width: fit-content;

  .custom-input {
    height: var(--height);
    width: 280px;
    padding: 0 15px;
    background: white;
    box-shadow: 0px 0px 0px 1px #dadada;
    border-radius: 5px;
    border: none;
    transition: 0.5s;
    margin-bottom: 4px;

    @include texts.text-3-regular;
    line-height: 18px;
    letter-spacing: -0.03em;

    color: #363740;

    &:focus {
      outline: none;
      box-shadow: 0px 0px 0px 2px colors.$blue-payee;
    }

    &:disabled {
      color: lightgray;
    }
  }

  .custom-input + .custom-label {
    position: absolute;
    top: 0px;
    left: 15px;
    @include texts.text-3-regular;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: -0.03em;
    height: var(--height);

    color: #7a7a7a;
    transition: 0.25s;
  }

  .custom-input + .custom-label.custom-label-top {
    height: 1px;
    top: -1px;
    font-size: 10px;
    padding: 0px 4px;
    background: white;
    color: #363740;
  }

  .custom-input:focus + .custom-label {
    height: 2px;
    top: -2px;
    font-size: 10px;
    padding: 0px 4px;
    background: white;
    color: #363740;
  }

  .custom-suffix {
    position: absolute;
    width: 30px;
    height: var(--height);
    top: 0px;
    right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;

    @include texts.text-3-regular;
    line-height: 18px;
    letter-spacing: -0.03em;

    color: #363740;
  }

  .mat-error {
    margin: 5px 0px;
    max-width: 305px;
  }

  .error-icon {
    width: 30px;
    height: var(--height);
    margin: 0;
    position: absolute;
    top: 0;
    right: -35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: colors.$error-primary;

    opacity: 0;
  }

  .errors-box {
    max-height: 0px;
    transition: 0.25s ease-in;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    animation: 0.25s autoToNull;
  }

  &.error {
    margin-right: 35px;
    .custom-input {
      box-shadow: 0px 0px 0px 2px colors.$error-primary;
    }

    .custom-label {
      color: colors.$error-primary !important;
    }

    .error-icon {
      opacity: 1;
    }

    .errors-box {
      max-height: 100px;
    }
  }
}

.mat-menu-panel {
  min-width: auto !important;
  max-width: none !important;
  min-height: auto !important;
}

.mat-menu-panel.nice-menu {
  border-radius: 0 !important;
  min-height: 0 !important;
  overflow-x: hidden !important;

  .mat-menu-content {
    padding: 5px !important;
  }
}

.mat-menu-item {
  &[width="shorter"] {
    width: #{sizes.$shorter-width};
  }

  &[width="short"] {
    width: #{sizes.$short-width};
  }

  &[width="normal"] {
    width: #{sizes.$normal-width};
  }

  &[width="wide"] {
    width: #{sizes.$wide-width};
  }

  &[width="wider"] {
    width: #{sizes.$wider-width};
  }

  &[width="widest"] {
    width: #{sizes.$widest-width};
  }
}

.no-padding .mat-menu-content {
  padding: 0 !important;
}

.mat-menu-item {
  @include texts.text-3-regular;
}

.custom-tooltip {
  position: relative;
  overflow: visible !important;
  @include texts.text-3-regular;
  font-weight: 300;
  color: white !important;
  background-color: #363740;
  max-width: unset !important;

  display: flex;
  align-items: center;
  justify-content: center;
  white-space: pre-line;
  padding: 10px !important;
}

app-custom-input-field[appearance="outline"],
app-custom-input-field:not([appearance]) {
  position: relative;
  display: flex;
  flex-direction: column;
  --height: 40px;
  --width: 250px;
  width: fit-content;

  [custom-input] {
    height: var(--height);
    width: var(--width);
    padding: 0 15px;
    background: white;
    box-shadow: 0px 0px 0px 1px #dadada;
    border-radius: 5px;
    border: none;
    transition: 0.5s;

    @include texts.text-3-regular;
    line-height: 24px;
    letter-spacing: -0.03em;

    color: #000000;

    &:focus {
      outline: none;
      box-shadow: 0px 0px 0px 2px colors.$blue-king;
      &::placeholder {
        opacity: 1;
      }
    }

    &:disabled {
      color: lightgray;
    }

    &::placeholder {
      transition: 0.2s ease-in;

      @include texts.text-3-regular;
      letter-spacing: -0.03em;

      color: #c4c4c4;
    }

    &:not(:focus)::placeholder {
      opacity: 0;
    }
  }

  [custom-input] + [custom-label] {
    position: absolute;
    top: 0px;
    left: 15px;
    @include texts.text-3-regular;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: -0.03em;
    height: var(--height);
    pointer-events: none;

    color: #7a7a7a;
    transition: 0.25s;
  }

  [custom-input] + [custom-label].custom-label-top,
  [custom-input]:focus + [custom-label] {
    height: 3px;
    top: -2.5px;
    font-size: 10px;
    padding: 0px 4px;
    background: white;
    color: #1c0f5a;
  }

  // [custom-input]:focus + [custom-label] {
  //   height: 2px;
  //   top: -2px;
  //   font-size: 10px;
  //   padding: 0px 4px;
  //   background: white;
  //   color: #363740;
  // }

  [custom-suffix] {
    position: absolute;
    width: var(--height);
    height: var(--height);
    top: 0px;
    left: calc(var(--width) - 15px);
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    pointer-events: none;

    @include texts.text-3-regular;
    letter-spacing: -0.03em;

    color: #000000;
  }

  .mat-error {
    width: fit-content;
    @include texts.text-1-regular;
    font-size: 11px;
    line-height: 25px;
  }

  [error-icon] {
    width: fit-content;
    height: var(--height);
    margin-left: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: colors.$error-primary;

    opacity: 0;
  }

  .errors-box {
    transition: height 0.25s ease-in;
    display: flex;
    flex-direction: column;
    width: var(--width);
    height: 0px;
    overflow: hidden;
  }

  .error {
    [custom-input] {
      box-shadow: 0px 0px 0px 2px colors.$error-primary;
    }

    [custom-label] {
      color: colors.$error-primary !important;
    }

    [error-icon] {
      opacity: 1;
    }
  }
}

app-custom-input-field[appearance="legacy"] {
  position: relative;
  display: flex;
  flex-direction: column;
  --height: 40px;
  --width: 280px;

  [custom-input] {
    height: var(--height);
    width: var(--width);
    padding: 0 15px;
    background: #f6f7f9;
    box-shadow: 0px 4px 0px #dfe0eb;
    border-radius: 5px;
    border: none;
    transition: 0.5s;
    margin-bottom: 4px;
    pointer-events: none;

    @include texts.text-3-regular;
    line-height: 18px;
    letter-spacing: -0.03em;

    color: #363740;

    &:focus {
      outline: none;
      background: #c4c4c4;
    }

    &:disabled {
      color: lightgray;
    }
  }

  [custom-suffix] {
    position: absolute;
    width: var(--height);
    height: var(--height);
    top: 0px;
    right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;

    @include texts.text-3-regular;
    line-height: 18px;
    letter-spacing: -0.03em;

    color: #363740;
  }

  .mat-error {
    width: fit-content;
    @include texts.text-1-regular;
    font-size: 11px;
    line-height: 25px;
  }

  [error-icon] {
    width: 30px;
    height: var(--height);
    margin: 0;
    position: absolute;
    top: 0;
    right: -35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .errors-box {
    transition: height 0.15s ease-in;
    display: flex;
    flex-direction: column;
    width: var(--width);
    overflow: hidden;
  }

  .error {
    [custom-input] {
      box-shadow:
        0px 4px 0px 0px colors.$error-primary,
        0px 0px 0px 1px colors.$error-primary;
    }
  }
}

.mat-tab-label-container {
  z-index: 0 !important;
}

.mat-tab-body {
  z-index: 0 !important;

  &:not(.mat-tab-body-active) {
    z-index: -1 !important;
  }
}
