// Brand Colors
$blue-payee: #d04a02; // #d04a02; // #455BED;
$blue-king: #eb8c00; // #9AA4BE; // #1089FF;
$brand-tertiary: #e8ebf0;
$brand-quaternary: #132043;

// Success
$success-primary: #26776D;

// Error
$error-primary: #E0301E;

// Warning
$warning-primary: #FEDACC;

// Blacks & Whites
$white: #FFFFFF;
$grey-light: #EDEDF6;
$grey-medium: #B4B4B4;
$grey-dark: #6D6B6B;
$black: #000000;

// Alert Colors
$alert-red: #E0301E; // #E26C6C;
$alert-green: #26776D; // #5FD754;
$alert-yellow: #FEDACC; // #FFECD0;

$blue-payee-contrast: white;
$blue-king-contrast: white;
$white-contrast: black;
$grey-light-contrast: $grey-dark;
$grey-medium-contrast: white;
$grey-dark-contrast: white;
$black-contrast: white;
$alert-red-contrast: white;
$alert-green-contrast: white;
$alert-yellow-contrast: white;
