@use "./variables/colors" as colors;
// @use "src/assets/shared/fonts/helvetica-neue/webfont/font.css";

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");
$tight-letter-spacing: -0.03em;

* {
  // TODO fallback font order, install avenir, use Roboto second then Helvetica, sans-serif
  // TODO choose google fonts if possible, import them in index.html and remove rest when finished with design
  font-family:
    PwC Helvetica Neue,
    sans-serif; // Avenir, Roboto, Helvetica, sans-serif;
  // font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  &[color="blue-payee"] {
    color: #{colors.$blue-payee};
  }

  &[color="blue-king"] {
    color: #{colors.$blue-king};
  }

  &[color="white"] {
    color: #{colors.$white};
  }

  &[color="grey-light"] {
    color: #{colors.$grey-light};
  }

  &[color="grey-medium"] {
    color: #{colors.$grey-medium};
  }

  &[color="grey-dark"] {
    color: #{colors.$grey-dark};
  }

  &[color="black"] {
    color: #{colors.$black};
  }

  &[color="alert-red"] {
    color: #{colors.$alert-red};
  }

  &[color="alert-green"] {
    color: #{colors.$alert-green};
  }

  &[color="alert-yellow"] {
    color: #{colors.$alert-yellow};
  }
}

h1 {
  font-weight: bold;
  font-size: 28px;
  line-height: 35px;
}

h2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

input,
textarea,
.input-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.input-label {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  text-align: left;
  align-self: flex-start;
}

.input-label-small {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;

  text-align: left;
  align-self: flex-start;
}

.technical-notes {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
}

button {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.mat-header-cell {
  color: #{colors.$blue-payee};
  vertical-align: middle;
  font-size: 0.75rem;
}

.mat-cell {
  vertical-align: middle;
  font-size: 0.875rem;
}

.mat-sort-header > .mat-sort-header-container,
.mat-sort-header-arrow,
.mat-sort-header-arrow * {
  color: inherit;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.progress-bar-text-active {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
}

.progress-bar-text {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
}
