@use "../variables/animations" as animations;
@use "../variables/colors" as colors;

[payee-ripple] {
  position: relative;
  overflow: hidden;
  pointer-events: none;
  border-radius: inherit;

  &.payee-ripple-unbound {
    overflow: visible;
    border-radius: none;
  }

  .payee-ripple-item {
    position: absolute;
    width: var(--size);
    height: var(--size);
    background-color: #{colors.$white};
    opacity: 0.2;
    border-radius: 50%;
    pointer-events: none;

    animation: grow 0.15s ease forwards;

    @keyframes grow {
      0% { transform: scale(0) }
      100% { transform: scale(1) }
    }
  }
}

[payee-ripple][color="white"] > .payee-ripple-item {
  background-color: darken(colors.$white, animations.$ripple-darken);
}
